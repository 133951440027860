<template>
  <LiefengContent>
    <template v-slot:title> 户代表管理 </template>
   
    <template v-slot:toolsbarRight>
       <Input
       :maxlength="20"
        v-model.trim="searchData.name"
        enter-button
        placeholder="姓名"
        style="width: 150px; margin-right: 10px"
        @on-enter="getList"
      />
      <Input
      :maxlength="20"
        v-model.trim="searchData.phone"
        enter-button
        placeholder="手机号"
        style="width: 150px"
        @on-enter="getList"
      />
      <Button
        style="margin: 0 5px 0 13px"
        type="primary"
        icon="ios-search"
        @click="search"
      >
        查询</Button
      ><Button
        style="margin: 0 5px 0 13px"
        icon="ios-refresh"
        type="success"
        @click="rest"
      >
        重置</Button
      >
      <Button type="warning" @click="$router.push('/mobilizeindex')">返回</Button>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        height="200"
        :loading="loading"
        :fixTable="true"
        :curPage="page"
        :pagesizeOpts="[20, 30, 50, 100]"
        :total="total"
        :page-size="pageSize"
        @hadlePageSize="getList"
      >
      </LiefengTable>

      <!-- 查看聊天记录弹窗 -->
      <LiefengModal
        title="个人聊天记录"
        :fullscreen="true"
        :value="chartModal"
        @input="chartModallFn"
      >
        <template v-slot:contentarea v-if="chartData">
          <Card dis-hover>
            <Row slot="title">
              <Col span="5" style="line-height: 32px"
                ><span>{{ chartData.name }}</span></Col
              >
              <Col span="5" style="line-height: 32px"
                ><span>{{ chartData.sexLabel }}</span></Col
              >
              <Col span="5" style="line-height: 32px"
                ><span>{{ chartData.phone }}</span></Col
              >
            </Row>
          </Card>

          <Table stripe :columns="chartTalbeColumns" :data="chartModalData">
            <template slot-scope="{row}" slot="msg">
              <div style="padding: 20px">
                <video :src="row.msg" v-if="row.msgType == '4'"></video>
                <audio :src="row.msg" v-else-if="row.msgType == '3'"></audio>
                <img
                  :src="row.msg"
                  alt=""
                  v-else-if="row.msgType == '2'"
                  style="width: 100px; max-height: 100px"
                />
                <div v-else>{{ row.msg }}</div>
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="status">
              <Checkbox
                :label="row.id"
                v-model="row.status"
                @on-change="changeStatus(row)"
                >公开</Checkbox
              >
            </template>
          </Table>
          <Page
          style="display: flex;justify-content: flex-end;margin-top: 14px"
            show-total
            @on-change="chartCurrentPageFn"
            @on-page-size-change="chartPageSizeFn"
            :total="chartTotal"
            size="small"
            show-sizer
            :page-size.sync="chartPageSize"
            :curret.sync="chartPageNum"
            show-elevator
          ></Page>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/mobilizecontacter")
import LiefengTable from "@/components/LiefengTable";
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
export default {
  data() {
    return {
      loading: false,
      urlParams: {
        groupId: "",
        orgCode: "",
      },
      total: 0,
      talbeColumns: [
        {
          title: "房号",
          key: "familyNo",
          width: 100,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 100,
          align: "center",
        },
        {
          title: "昵称",
          key: "nickname",
          width: 120,
          align: "center",
        },

        {
          title: "性别",
          key: "sexLabel",
          width: 80,
          align: "center",
        },
        {
          title: "联系电话",
          key: "phone",
          width: 130,
          align: "center",
        },
        {
          title: "身份类型",
          key: "userTypeLabel",
          width: 130,
          align: "center",
        },
        {
          title: "出生日期",
          key: "birthdayValue",
          width: 120,
          align: "center",
        },
        {
          title: "所在详细地址",
          key: "detailAddr",
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  domProps: {
                    title: params.row.roadName + params.row.detailAddr,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                    },
                  },
                },
                params.row.roadName + params.row.detailAddr
              ),
            ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 80,
          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "info",
                        size: "small",
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.setManage(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        params.row.manager == 1 ? "设为负责人" : "取消负责人" // 0 是 1 否"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.remove(params.row);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "移除户代表"
                      ),
                      // h(
                      //   "DropdownItem",
                      //   {
                      //     nativeOn: {
                      //       click: () => {
                      //         this.myChart(params.row);
                      //       },
                      //     },
                      //     style: {
                      //       textAlign: "center",
                      //     },
                      //   },
                      //   "查看个人聊天"
                      // ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      tableData: [],
      searchData: {
        name: "",
        phone: "",
        pageSize: 20,
        page: 1,
      },
      custGlobalId: "",
      page: 1,
      pageSize: 20,
      //查看聊天记录
      chartModal: false,
      chartData: null,
      chartModalData: [],
      chartTotal: 0,
      chartPageNum: 1,
      chartPageSize: 10,
      chartTalbeColumns: [
        {
          title: "时间",
          key: "gmtCreate",
          width: 180,
          align: "center",
        },
        {
          title: "内容",
          slot: "msg",
          align: "center",
        },
        {
          title: "状态",
          slot: "status",
          width: 110,
          align: "center",
        },
        {
          title: "操作",
          width: 110,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      // this.setManage(params.row);
                    },
                  },
                },
                "回复"
              ),
            ]);
          },
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.data) {
      let Base64 = require("js-base64").Base64;

      let temp = Base64.decode(this.$route.query.data);
      this.urlParams = JSON.parse(temp);
      console.log(this.urlParams);
    }
    this.custGlobalId = this.GetUrlMenuCode()
      ? this.GetUrlMenuCode()
      : "C1803272004238a9dc64d978532";
    this.getList();
  },
  methods: {
    //个人聊天记录弹窗状态改变
    chartModallFn(status) {
      this.chartModal = status;
    },
    GetUrlMenuCode() {
      let url = window.location.href;
      let parameter = url.substring(url.indexOf("?") + 1);
      parameter = parameter.split("&");
      let reg = /custGlobalId=/g;
      let menuCode = "";
      for (let i = 0; i < parameter.length; i++) {
        reg.lastIndex = 0;
        if (reg.test(parameter[i])) {
          menuCode = parameter[i].replace("custGlobalId=", "");
          break;
        }
      }
      return menuCode;
    },
    search() {
      this.searchData = {
        name: this.searchData.name,
        phone: this.searchData.phone,
        pageSize: 20,
        page: 1,
      };
      this.getList(this.searchData);
    },
    rest() {
      this.searchData = {
        name: "",
        phone: "",
        page: 1,
        pageSize: 20,
      };
      this.getList(this.searchData);
    },
    getList(data) {
      this.loading = true;
      if (data && data.page) {
        //改变当前页
        this.searchData.page = data.page;
        // this.searchData.page = 1;
        this.searchData.pageSize = data.pageSize;
      }
      if (data && data.page && (data.name || data.phone)) {
        //搜索
        this.searchData = {
          ...data,
        };
      }
      let params = {
        ...this.searchData,
        status: 2, //必填
        roadCode: "",
        projectCode: "",
        orgCode: this.urlParams.orgCode, //必填   暂时随便填，以后是组织机构编码

        groupId: this.urlParams.groupId,
        oemCode: parent.vue.loginInfo.userinfo.oemCode, //必填  缓存拿
      };

      this.$get(
        "/sendmsg/pc/chat/groupmanager/selectChatManagerUserPage",
        params
      )

        // this.$get("/syaa/pc/sy/user/residents/selectSyFamilyMemberPage", params)
        .then((res) => {
          if (res.code === "200") {
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.page = res.currentPage;
            this.loading = false;
          }else {
            this.$Message.error({
              background: true,
              content: "数据获取失败"
            })
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$Message.error({
              background: true,
              content: "数据获取失败"
            })
            this.loading = false;
        });
    },
    setSelection(selection) {
      this.selection = selection;
    },
    addUser() {
      this.$Message.error("暂时接口");
    },
    setManage(row) {
      if (row.manager === 1) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认设为负责人吗？</p>",
          cancelText: "取消",
          okText: "确认",
          onOk: () => {
            let params = {
              groupId: this.urlParams.groupId,
              manager: row.manager,
              managerId: row.custGlobalId,
              oemCode: parent.vue.loginInfo.userinfo.oemCode,
              operCustGlobalId: this.custGlobalId,
            };
            this.$post("/sendmsg/pc/chat/groupmanager/addManager", params)
              .then((res) => {
                if (res.code === "200") {
                  this.$Message.success("操作成功");
                  this.getList();
                }
              })
              .catch((err) => console.log(err));
          },
        });
      } else {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认取消负责人吗？</p>",
          cancelText: "取消",
          okText: "确认",
          onOk: () => {
            let params = {
              groupId: this.urlParams.groupId,
              manager: row.manager,
              custGlobalId: row.custGlobalId,
              operCustGlobalId: this.custGlobalId,
            };
            this.$post("/sendmsg/pc/chat/groupmanager/delManager", params)
              .then((res) => {
                if (res.code === "200") {
                  this.$Message.success("操作成功");
                  this.getList();
                }else {
                   this.$Message.error("操作失败");
                }
              })
              .catch((err) => console.log(err));
          },
        });
      }
    },
    remove(data) {
      let params = {};
      if (data) {
        params = {
          userId: data.userId,
          familyId: data.familyId,
          contact: data.contact,
        };
      }
      this.$post("/syaa/pc/sy/user/contact/changeFamilyContact", params)
        .then((res) => {
          if (res.code === "200") {
            this.$Message.success("操作成功");
            this.getList();
          }else {
            this.$Message.success("操作失败");
          }
        })
        .catch((err) => console.log(err));
    },
    //查看个人聊天
    myChart(row) {
      this.chartData = {
        name: row.name,
        houseNum: row.houseNum,
        sexLabel: row.sexLabel,
        phone: row.phone,
        chatUserId: row.custGlobalId,
        groupId: this.urlParams.groupId,
      };
      this.getChartList();
      this.chartModal = true;
    },
    chartCurrentPageFn(page) {
      this.chartPageNum = page;
      this.getChartList();
    },
    chartPageSizeFn(pageSize) {
      this.chartPageSize = pageSize;
      this.getChartList();
    },
    getChartList() {
      let params = {
        groupId: this.chartData.groupId,
        chatUserId: this.chartData.chatUserId,
        page: this.chartPageNum,
        pageSize: this.chartPageSize,
      };
      this.$get("/sendmsg/pc/chat/record/selectRecordPage", params)
        .then((res) => {
          if (res.code === "200") {
            res.dataList.map((item) => {
              item.status = item.status === 1 ? false : true; //0公开 1隐藏
            });
            this.chartModalData = res.dataList;
            this.chartTotal = res.maxCount;
          }else {
            this.$Message.error({
              background: true,
              content: "数据获取失败"
            })
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeStatus(data) {
      if (data.id) {
        let params = {
          recordId: data.id,
          status: data.status ? 0 : 1,
        };
        this.$post("/sendmsg/pc/chat/record/changeRecordStatus", params)
          .then((res) => {
            if (res.code === "200") {
              // console.log(res)
              this.$Message.success("操作成功");
            }else {
              this.$Message.success("操作失败");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  components: {
    LiefengTable,
    LiefengContent,
    LiefengModal,
  },
};
</script>
    
<style scoped lang='less'>
</style>